import "formatted-time";
import "js-alerts";
import "field-container";
import "onspace/assets/elements/calendar";
import "onspace/assets/elements/fields/rich_text.scss";
import "onspace/assets/elements/form";
import "onspace/assets/elements/pagination";
import "onspace/assets/elements/popover";
import "onspace/assets/elements/toggle";
import "scss-utils/button";

import "base/color.scss";
import "base/layout.scss";
import "base/typography.scss";
import "base/reset.scss";

import analytics from "components/analytics";
import "components/assets";
import "components/links";
import "components/scroll";
import "components/ujs";

import "elements/advertising/placement";
import "elements/badge";
import "elements/button";
import "elements/collection";
import "elements/content";
import "elements/footer";
import "elements/grid";
import "elements/layout.scss";
import "elements/list";
import "elements/map";
import "elements/nav_bar";
import "elements/page";
import "elements/popover";
import "elements/rich_text";
import "elements/search/autocomplete";
import "elements/search/form";
import "elements/share";
import "elements/svg";
import "elements/video";
import "elements/video/card";
import "elements/video/iframe";
import "elements/video/educational_video_card";
import "elements/video/list";

import "../components/font";
import "../elements/accordion";
import "../elements/ambassadors/card";
import "../elements/ambassadors/content";
import "../elements/banner";
import "../elements/button";
import "../elements/contact";
import "../elements/footers";
import "../elements/glide";
import "../elements/image_gallery";
import "../elements/nav_bar_ext";
import "../elements/page_layout";
import "../elements/dogs/card";
import "../elements/dogs/content";
import "../elements/dogs/list";
import "../elements/dogs/profile_card";
import "../elements/dogs/status";
import "../elements/events/content";
import "../elements/events/booking_form";
import "../elements/events/header";
import "../elements/events/table";
import "../elements/faqs/accordion";
import "../elements/forms/form";
import "../elements/home/content";
import "../elements/news/archive";
import "../elements/news/article";
import "../elements/news/card";
import "../elements/news/content";
import "../elements/news/list";
import "../elements/profile";

window.analytics = analytics;

analytics.injectGoogleAnalytics(window.config.googleAnalyticsId);
analytics.injectGoogleTagManager(window.config.googleTagId);
analytics.injectGoogleTagManager(window.config.googleTagIdSecondary);
analytics.setupTurbolinksTracking();
