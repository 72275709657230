import Glide from '@glidejs/glide'
import './glide.scss'

const BASE_CONFIG = {
  type: 'carousel',
  autoplay: 4000,
  animationDuration: 500,
  animationTimingFunc: 'ease-in-out',
  hoverpause: true,
  keyboard: true,
  touchAngle: 90,
  touchRatio: 0.2,
  startsAt: 1,
  focusAt: 0,
  gap: 25,
  peek: {
    before: 50,
    after: 50
  }
}

const HOME_HERO_CONFIG = {
  ...BASE_CONFIG,
  perView: 4,
  breakpoints: {
    450: {
      perView: 1
    },
    700: {
      perView: 2
    },
    1200: {
      perView: 3
    }
  }
}

const SLIDER_CONFIG = {
  ...BASE_CONFIG,
  perView: 3,
  breakpoints: {
    450: {
      perView: 1
    },
    700: {
      perView: 2
    },
    1200: {
      perView: 3
    }
  }
}

// eslint-disable-next-line space-before-function-paren
document.addEventListener('turbolinks:load', function () {
  if (document.querySelector('#slider')) {
    const sliderGlide = new Glide('#slider', SLIDER_CONFIG)
    sliderGlide.mount()
  }

  if (document.querySelector('#home-hero-slider')) {
    const homeAdoptionHeroGlide = new Glide(
      '#home-hero-slider',
      HOME_HERO_CONFIG
    )
    homeAdoptionHeroGlide.mount()
  }
})
