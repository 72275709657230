import "./accordion.scss";

import CustomHTMLElement from "html-element";
import { sendRequest } from "js-api";

////////// Generic

function makeAccordion(element) {
  element.anchor = element.querySelector(".accordion-anchor");
  element.content = element.querySelector(".accordion-content");

  element.anchor.addEventListener("click", (event) => {
    let target = event.target;
    while (target && target !== element) {
      if (target.nodeName === "A" && target.href) {
        return;
      }

      target = target.parentElement;
    }

    element.toggleContent();
  });

  ////////// State

  element.contentActive = element.classList.contains("accordion--active");

  element.toggleContent = function () {
    if (this.contentActive) {
      this.hideContent();
    } else {
      this.showContent();
    }
  };

  element.showContent = function () {
    if (this.contentActive) {
      return;
    }

    if (this.shouldLoadContent) {
      this.shouldLoadContent = false;
      this.loadContent(this.contentUrl);
    }

    this.contentActive = true;
    this.classList.add("accordion--active");

    if (this.anchor.classList.contains("button")) {
      this.anchor.classList.add("button--active");
    }
  };
  element.hideContent = function () {
    if (!this.contentActive) {
      return;
    }

    this.contentActive = false;
    this.classList.remove("accordion--active");

    this.anchor.classList.remove("button--active");
  };

  ////////// Remote Content

  if (element.hasAttribute("data-content-url")) {
    element.contentUrl = element.getAttribute("data-content-url");
    element.shouldLoadContent = true;
  }

  element.showLoading = function () {
    this.content.innerText = "Loading...";
  };

  element.loadContent = async function (url) {
    this.showLoading();

    let response = await sendRequest("GET", url, {
      headers: { "X-Application-Layout": "injection" },
    });
    this.displayContent(response.content);
  };
  element.displayContent = function (content) {
    this.content.innerHTML = "";

    let body = content.querySelector("body");
    let children = body.childNodes;
    for (let i = children.length; i > 0; i--) {
      let child = children[i - 1];
      this.content.insertBefore(child, this.content.firstChild);
    }

    document.triggerEvent("accordion:load", this.content);
  };
}

////////// Element

export default class Accordion extends CustomHTMLElement {
  _runSetup() {
    super._runSetup();

    makeAccordion(this);
  }
}
window.customElements.define("accord-ion", Accordion);

//////////

document.addEventListener("turboujs:load", (event) => {
  document
    .querySelectorAll("[data-accordion]")
    .forEach((element) => makeAccordion(element));
});
