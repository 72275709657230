import './image_gallery.scss'

import CustomHTMLElement from 'html-element'

export default class ImageGallery extends CustomHTMLElement {
  _runSetup() {
    super._runSetup()

    this.addEventListener('click', this.showGallery.bind(this))

    this.imageElements = Array.from(this.querySelectorAll('[data-img-src]'))
    this.indexElementLength = this.imageElements.length
    this.imageUrls = this.imageElements.map((img) =>
      img.getAttribute('data-img-src')
    )

    this.setupEvent()
  }

  setupEvent() {
    this.index = 0

    this.closeButtonElement = document.querySelector('.gallery-button-close')
    this.arrowLeftElement = document.querySelector('.gallery-arrow-left')
    this.arrowRightElement = document.querySelector('.gallery-arrow-right')

    this.closeButtonElement.addEventListener('click', (event) => {
      this.hideGallery(event, true)
    })
    this.arrowLeftElement.addEventListener('click', () => {
      this.arrowLeft(this.index)
    })
    this.arrowRightElement.addEventListener('click', () => {
      this.arrowRight(this.index)
    })
  }

  /// /////  Show/Hide

  showGallery(event) {
    let clickedElement = document.elementFromPoint(event.x, event.y)
    let index = this.imageElements.indexOf(clickedElement) || 0

    if (!clickedElement.hasAttribute('data-img-src')) {
      return event.preventDefault()
    }

    if (this.indexElementLength > 1) {
      this.arrowLeftElement.classList.add('gallery-arrow-left--active')
      this.arrowRightElement.classList.add('gallery-arrow-right--active')
    }

    document.body.appendChild(this.galleryElement)
    document.body.classList.add('image-gallery-active')
    this.closeButtonElement.classList.add('gallery-button-close--active')
    this.chooseImage(index)
  }

  hideGallery(event, closeButton = false) {
    if (!closeButton) return event.preventDefault()

    document.body.classList.remove('image-gallery-active')
    this.closeButtonElement.classList.remove('gallery-button-close--active')
    this.arrowLeftElement.classList.remove('gallery-arrow-left--active')
    this.arrowRightElement.classList.remove('gallery-arrow-right--active')
    this.galleryElement.remove()
  }

  /// /////  Left & Right Arrows

  arrowLeft(currentIndex) {
    this.index = currentIndex - 1

    if (this.index < 0) {
      this.index = this.indexElementLength - 1
    }

    return this.chooseImage(this.index)
  }

  arrowRight(currentIndex) {
    this.index = currentIndex + 1

    if (this.index > this.indexElementLength - 1) {
      this.index = 0
    }

    return this.chooseImage(this.index)
  }

  chooseImage(index) {
    this.index = index
    this.imageElement.style.backgroundImage = `url('${this.imageUrls[index]}')`
  }

  get galleryElement() {
    if (!this._galleryElement) {
      this._galleryElement = document.createElement('div')
      this._galleryElement.classList.add('image-gallery')
      this._galleryElement.addEventListener(
        'click',
        this.hideGallery.bind(this)
      )

      this.imageContentElement = document.createElement('div')
      this.imageContentElement.classList.add('image-gallery-content')

      this.imageElement = document.createElement('div')
      this.imageElement.classList.add('image-gallery-content-item')

      this._galleryElement.append(this.imageContentElement)
      this.imageContentElement.append(this.closeButtonElement)
      this.imageContentElement.append(this.arrowLeftElement)
      this.imageContentElement.appendChild(this.imageElement)
      this.imageContentElement.append(this.arrowRightElement)
    }

    return this._galleryElement
  }
}

window.customElements.define('image-gallery', ImageGallery)
